export function shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];

        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}

export function insertRandomly<T>(array: T[], item: T) {
    let index = Math.floor(Math.random() * (array.length + 1));

    array.splice(index, 0, item);
}