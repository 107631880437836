import { App } from './app';
import { BACK_ARROW_PATH, ExerciceKind, EXERCICE_DATA, Item, ItemBucket, Section } from './constants';
import { appendCheckbox, appendElement, appendHeader, clearElement, createPage } from './dom';
import { insertRandomly, shuffleArray } from './utils';

const VOWELS = ['a', 'e', 'i', 'o', 'u'];

export type PracticeParameters = {
    exercice: ExerciceKind;
    buckets: ItemBucket[];
}

export function displayPracticePage(section: Section, params: PracticeParameters) {
    let page = createPage();
    let buckets = params.buckets;
    let exercice = EXERCICE_DATA[params.exercice];
    let allItems = buckets.map(bucket => bucket.items).flat();
    let submitOnVowel = section.itemKind === 'character';
    let useSelfEvaluateMode = localStorage.getItem('self-evaluate-mode') === 'true';

    appendHeader(page, {
        text: section.name,
        buttons: [{
            icon: BACK_ARROW_PATH,
            onClick: () => App.goBack(),
        }]
    });

    let instruction = appendElement(page, 'div', {
        textContent: exercice.instruction.replace('$KIND', section.itemKind),
        className: 'instruction'
    });

    // let selfEvaluateDiv = appendElement(page, 'div', {
    //     className: 'self-evaluate-div',
    //     textContent: '• '
    // });

    // let selfEvaluateLabel = `Self evaluate mode <span class="small">(think of the answer and input if you were right or wrong).</span>`;
    let selfEvaluateLabel = `Self-evaluate mode`;
    let selfEvaluateCheckbox = appendCheckbox(page, {
        // prefix: '• ',
        label: selfEvaluateLabel,
        onChange: () => {
            updateSelfEvaluateMode();
        }
    });

    selfEvaluateCheckbox.classList.add('self-evaluate-div');

    let progressDiv = appendElement(page, 'div', {
        className: 'progress'
    });

    let successCount = appendElement(progressDiv, 'span', {
        className: 'success-count',
        textContent: '0'
    });

    appendElement(progressDiv, 'span', {
        textContent: ' successes, '
    });

    let failCount = appendElement(progressDiv, 'span', {
        className: 'fail-count',
        textContent: '0'
    });

    appendElement(progressDiv, 'span', {
        textContent: ' mistakes, '
    });

    let remaining: HTMLSpanElement = appendElement(progressDiv, 'span', {
        className: 'remaining-count',
        textContent: '0'
    });

    appendElement(progressDiv, 'span', {
        textContent: ' remaining'
    });

    let container: HTMLDivElement = appendElement(page, 'div', {
        style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    });

    let prompt: HTMLDivElement = appendElement(container, 'div', {
        className: 'prompt',
    });

    let input: HTMLInputElement = appendElement(container, 'input', {
        type: 'text',
        className: 'input-box'
    });

    input.autocapitalize = 'off';

    let help = appendElement(container, 'div', {
        className: 'help',
        style: { display: 'flex' }
    });

    let answer = appendElement(help, 'div', {
        textContent: '',
        className: 'answer'
    });

    let cross = appendElement(help, 'div', {
        
    });

    appendElement(cross, 'img', {
        src: '/images/cross.svg',
        style: { width: '14px' }
    })

    let buttons = appendElement(container, 'div', {
        style: {
            width: '270px',
            display: 'flex',
            justifyContent: 'center',
        }
    });

    let helpButton = appendElement(buttons, 'div', {
        textContent: 'Show answer',
        className: 'button'
    });

    let submitButton = appendElement(buttons, 'div', {
        textContent: 'Submit',
        className: 'button'
    });

    let forceSuccessButton = appendElement(null, 'div', {
        className: 'small-button',
    });

    appendElement(forceSuccessButton, 'img', {
        src: '/images/checkmark.svg',
    });

    let forceFailureButton = appendElement(null, 'div', {
        className: 'small-button',
    });

    appendElement(forceFailureButton, 'img', {
        src: '/images/cross.svg',
    });

    let goBackButton = appendElement(null, 'div', {
        textContent: 'Go back',
        className: 'button',
        onClick: () => App.goBack()
    });

    let retryButton = appendElement(null, 'div', {
        textContent: 'Retry',
        className: 'button',
        onClick: () => {
            App.goBack();
            displayPracticePage(section, params);
        }
    })

    let itemQueue = shuffleArray(allItems.slice());
    let failedItems = new Set();
    let succeededItems = new Set();
    let currentItem: Item | null = null;
    let inputOccured = false;

    let showNext = () => {
        if (!itemQueue.length && !currentItem) {
            return;
        }

        resetHelp();
        currentItem = itemQueue.pop();
        input.value = '';

        if (currentItem) {
            prompt.textContent = exercice.getPrompt(currentItem);
            remaining.textContent = (itemQueue.length + 1).toString();
            input.focus();
        } else {
            onFinish();
        }

        setTimeout(() => input.value = ''); // somehow this is necessary on mobile
    };

    let onFinish = () => {
        // prompt.textContent = `${succeededItems.size} / ${allItems.length}`;
        prompt.textContent = '';
        
        remaining.textContent = '0';
        input.disabled = true;
        selfEvaluateCheckbox.disabled = true;
        instruction.textContent = 'All done!';
        instruction.style.fontWeight = 'bold';
        instruction.style.textAlign = 'center';
        clearElement(buttons);
        buttons.appendChild(retryButton);
        buttons.appendChild(goBackButton);
    };

    let submit = () => {
        let actual = input.value.trim().toLowerCase();
        let isValidAnswer = exercice.isValidAnswer(currentItem!, actual);

        if (!actual || !inputOccured) {
            showHelp();
            onFailure();
        } else if (!isValidAnswer) {
            showCross();
            onFailure();
        } else {
            onSuccess();
        }

        inputOccured = false;
        input.focus();
    };

    let onSuccess = () => {
        if (!failedItems.has(currentItem)) {
            succeededItems.add(currentItem);
        }

        successCount.textContent = `${succeededItems.size}`;
        showNext();
    };

    let onFailure = () => {
        failedItems.add(currentItem);

        if (!itemQueue.includes(currentItem)) {
            insertRandomly(itemQueue, currentItem);
        }

        failCount.textContent = `${failedItems.size}`;
    };

    let resetForceButtons = () => {
        clearElement(buttons);
        buttons.appendChild(helpButton);
    };

    let forceSuccess = () => {
        resetForceButtons();
        onSuccess();
    };

    let forceFailure = () => {
        resetForceButtons();
        onFailure();
        showNext();
    };

    let showHelp = () => {
        answer.innerHTML = exercice.getHelpHtml(currentItem!);
        answer.style.display = 'inline';
        cross.style.display = 'none';
        input.focus();
    };

    let showCross = () => {
        answer.style.display = 'none';
        cross.style.display = 'inline';
    };

    let resetHelp = () => {
        answer.style.display = 'none';
        cross.style.display = 'none';
    };

    let updateSelfEvaluateMode = () => {
        let selfEvaluateEnabled = selfEvaluateCheckbox.checked;

        clearElement(buttons);
        resetHelp();

        buttons.appendChild(helpButton);

        if (selfEvaluateEnabled) {
            input.value = '';
            input.disabled = true;
            instruction.textContent = exercice.instructionSelfEvaluate.replace('$KIND', section.itemKind);
        } else {
            input.disabled = false;
            instruction.textContent = exercice.instruction.replace('$KIND', section.itemKind);
            buttons.appendChild(submitButton);
            input.focus();
        }

        localStorage.setItem('self-evaluate-mode', selfEvaluateEnabled.toString());
    };

    App.displayPage(page);

    input.onkeydown = (evt) => {
        if (evt.key === 'Enter') {
            submit();
            evt.preventDefault();
        }
    };

    if (section.itemKind === 'character') {
        input.autocomplete = 'off';
    }

    input.spellcheck = false;

    input.onbeforeinput = (evt) => {
        if (evt.data) {
            inputOccured = true;

            if (submitOnVowel && (VOWELS.some(letter => evt.data?.endsWith(letter)) || (evt.data === 'n' && input.value === '' && ['ん', 'ン'].includes(prompt.textContent!)))) {
                setTimeout(() => submit());
            }
        }

        setTimeout(() => {
            if (input.value === '') {
                resetHelp();
            }
        }, 1);
    };

    submitButton.onclick = submit;
    forceSuccessButton.onclick = forceSuccess;
    forceFailureButton.onclick = forceFailure;
    helpButton.onclick = () => {
        showHelp();

        if (selfEvaluateCheckbox.checked) {
            clearElement(buttons);
            buttons.appendChild(forceSuccessButton);
            buttons.appendChild(forceFailureButton);
        } else {
            onFailure();
        }
    };

    if (useSelfEvaluateMode) {
        // @ts-ignore
        selfEvaluateCheckbox._checked = true;
    }

    updateSelfEvaluateMode();
    showNext();
}