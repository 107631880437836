import EXERCICES from '../data/exercices.json'
import { displayMenuPage } from './menu-page';
import { displayPracticePage } from './practice-page';
import { displayPreparationPage } from './preparation-page';

function main() {
    displayMenuPage();
    // displayPreparationPage(EXERCICES[0]);
    // displayPracticePage(EXERCICES[0], { buckets: EXERCICES[0].buckets.slice(0, 1), exercice: 'read' });
}

main();