[
  {
    "name": "Hiragana",
    "exercices": [
      "read"
    ],
    "itemKind": "character",
    "wrapBuckets": false,
    "buckets": [
      {
        "name": "Vowels (5)",
        "items": [
          {
            "furigana": "あ",
            "roumaji": "a"
          },
          {
            "furigana": "い",
            "roumaji": "i"
          },
          {
            "furigana": "う",
            "roumaji": "u"
          },
          {
            "furigana": "え",
            "roumaji": "e"
          },
          {
            "furigana": "お",
            "roumaji": "o"
          }
        ]
      },
      {
        "name": "K-column (5)",
        "items": [
          {
            "furigana": "か",
            "roumaji": "ka"
          },
          {
            "furigana": "き",
            "roumaji": "ki"
          },
          {
            "furigana": "く",
            "roumaji": "ku"
          },
          {
            "furigana": "け",
            "roumaji": "ke"
          },
          {
            "furigana": "こ",
            "roumaji": "ko"
          }
        ]
      },
      {
        "name": "S-column (5)",
        "items": [
          {
            "furigana": "さ",
            "roumaji": "sa"
          },
          {
            "furigana": "し",
            "roumaji": "shi"
          },
          {
            "furigana": "す",
            "roumaji": "su"
          },
          {
            "furigana": "せ",
            "roumaji": "se"
          },
          {
            "furigana": "そ",
            "roumaji": "so"
          }
        ]
      },
      {
        "name": "T-column (5)",
        "items": [
          {
            "furigana": "た",
            "roumaji": "ta"
          },
          {
            "furigana": "ち",
            "roumaji": "chi"
          },
          {
            "furigana": "つ",
            "roumaji": "tsu"
          },
          {
            "furigana": "て",
            "roumaji": "te"
          },
          {
            "furigana": "と",
            "roumaji": "to"
          }
        ]
      },
      {
        "name": "N-column (5)",
        "items": [
          {
            "furigana": "な",
            "roumaji": "na"
          },
          {
            "furigana": "に",
            "roumaji": "ni"
          },
          {
            "furigana": "ぬ",
            "roumaji": "nu"
          },
          {
            "furigana": "ね",
            "roumaji": "ne"
          },
          {
            "furigana": "の",
            "roumaji": "no"
          }
        ]
      },
      {
        "name": "H-column (5)",
        "items": [
          {
            "furigana": "は",
            "roumaji": "ha"
          },
          {
            "furigana": "ひ",
            "roumaji": "hi"
          },
          {
            "furigana": "ふ",
            "roumaji": "fu"
          },
          {
            "furigana": "へ",
            "roumaji": "he"
          },
          {
            "furigana": "ほ",
            "roumaji": "ho"
          }
        ]
      },
      {
        "name": "M-column (5)",
        "items": [
          {
            "furigana": "ま",
            "roumaji": "ma"
          },
          {
            "furigana": "み",
            "roumaji": "mi"
          },
          {
            "furigana": "む",
            "roumaji": "mu"
          },
          {
            "furigana": "め",
            "roumaji": "me"
          },
          {
            "furigana": "も",
            "roumaji": "mo"
          }
        ]
      },
      {
        "name": "Y-column (3)",
        "items": [
          {
            "furigana": "や",
            "roumaji": "ya"
          },
          {
            "furigana": "ゆ",
            "roumaji": "yu"
          },
          {
            "furigana": "よ",
            "roumaji": "yo"
          }
        ]
      },
      {
        "name": "R-column (5)",
        "items": [
          {
            "furigana": "ら",
            "roumaji": "ra"
          },
          {
            "furigana": "り",
            "roumaji": "ri"
          },
          {
            "furigana": "る",
            "roumaji": "ru"
          },
          {
            "furigana": "れ",
            "roumaji": "re"
          },
          {
            "furigana": "ろ",
            "roumaji": "ro"
          }
        ]
      },
      {
        "name": "W-column (3)",
        "items": [
          {
            "furigana": "わ",
            "roumaji": "wa"
          },
          {
            "furigana": "を",
            "roumaji": "wo"
          },
          {
            "furigana": "ん",
            "roumaji": "n"
          }
        ]
      },
      {
        "name": "Dakuten (25)",
        "items": [
          {
            "furigana": "が",
            "roumaji": "ga"
          },
          {
            "furigana": "ぎ",
            "roumaji": "gi"
          },
          {
            "furigana": "ぐ",
            "roumaji": "gu"
          },
          {
            "furigana": "げ",
            "roumaji": "ge"
          },
          {
            "furigana": "ご",
            "roumaji": "go"
          },
          {
            "furigana": "ざ",
            "roumaji": "za"
          },
          {
            "furigana": "じ",
            "roumaji": "ji"
          },
          {
            "furigana": "ず",
            "roumaji": "zu"
          },
          {
            "furigana": "ぜ",
            "roumaji": "ze"
          },
          {
            "furigana": "ぞ",
            "roumaji": "zo"
          },
          {
            "furigana": "だ",
            "roumaji": "da"
          },
          {
            "furigana": "ぢ",
            "roumaji": "ji"
          },
          {
            "furigana": "づ",
            "roumaji": "zu"
          },
          {
            "furigana": "で",
            "roumaji": "de"
          },
          {
            "furigana": "ど",
            "roumaji": "do"
          },
          {
            "furigana": "ば",
            "roumaji": "ba"
          },
          {
            "furigana": "び",
            "roumaji": "bi"
          },
          {
            "furigana": "ぶ",
            "roumaji": "bu"
          },
          {
            "furigana": "べ",
            "roumaji": "be"
          },
          {
            "furigana": "ぼ",
            "roumaji": "bo"
          },
          {
            "furigana": "ぱ",
            "roumaji": "pa"
          },
          {
            "furigana": "ぴ",
            "roumaji": "pi"
          },
          {
            "furigana": "ぷ",
            "roumaji": "pu"
          },
          {
            "furigana": "ぺ",
            "roumaji": "pe"
          },
          {
            "furigana": "ぽ",
            "roumaji": "po"
          }
        ]
      },
      {
        "name": "Combinations (33)",
        "items": [
          {
            "furigana": "きゃ",
            "roumaji": "kya"
          },
          {
            "furigana": "きゅ",
            "roumaji": "kyu"
          },
          {
            "furigana": "きょ",
            "roumaji": "kyo"
          },
          {
            "furigana": "しゃ",
            "roumaji": "sha"
          },
          {
            "furigana": "しゅ",
            "roumaji": "shu"
          },
          {
            "furigana": "しょ",
            "roumaji": "sho"
          },
          {
            "furigana": "ちゃ",
            "roumaji": "cha"
          },
          {
            "furigana": "ちゅ",
            "roumaji": "chu"
          },
          {
            "furigana": "ちょ",
            "roumaji": "cho"
          },
          {
            "furigana": "にゃ",
            "roumaji": "nya"
          },
          {
            "furigana": "にゅ",
            "roumaji": "nyu"
          },
          {
            "furigana": "にょ",
            "roumaji": "nyo"
          },
          {
            "furigana": "ひゃ",
            "roumaji": "hya"
          },
          {
            "furigana": "ひゅ",
            "roumaji": "hyu"
          },
          {
            "furigana": "ひょ",
            "roumaji": "hyo"
          },
          {
            "furigana": "みゃ",
            "roumaji": "mya"
          },
          {
            "furigana": "みゅ",
            "roumaji": "myu"
          },
          {
            "furigana": "みょ",
            "roumaji": "myo"
          },
          {
            "furigana": "りゃ",
            "roumaji": "rya"
          },
          {
            "furigana": "りゅ",
            "roumaji": "ryu"
          },
          {
            "furigana": "りょ",
            "roumaji": "ryo"
          },
          {
            "furigana": "ぎゃ",
            "roumaji": "gya"
          },
          {
            "furigana": "ぎゅ",
            "roumaji": "gyu"
          },
          {
            "furigana": "ぎょ",
            "roumaji": "gyo"
          },
          {
            "furigana": "じゃ",
            "roumaji": "ja"
          },
          {
            "furigana": "じゅ",
            "roumaji": "ju"
          },
          {
            "furigana": "じょ",
            "roumaji": "jo"
          },
          {
            "furigana": "びゃ",
            "roumaji": "bya"
          },
          {
            "furigana": "びゅ",
            "roumaji": "byu"
          },
          {
            "furigana": "びょ",
            "roumaji": "byo"
          },
          {
            "furigana": "ぴゃ",
            "roumaji": "pya"
          },
          {
            "furigana": "ぴゅ",
            "roumaji": "pyu"
          },
          {
            "furigana": "ぴょ",
            "roumaji": "pyo"
          }
        ]
      }
    ]
  },
  {
    "name": "Katakana",
    "exercices": [
      "read"
    ],
    "itemKind": "character",
    "wrapBuckets": false,
    "buckets": [
      {
        "name": "Vowels (5)",
        "items": [
          {
            "furigana": "ア",
            "roumaji": "a"
          },
          {
            "furigana": "イ",
            "roumaji": "i"
          },
          {
            "furigana": "ウ",
            "roumaji": "u"
          },
          {
            "furigana": "エ",
            "roumaji": "e"
          },
          {
            "furigana": "オ",
            "roumaji": "o"
          }
        ]
      },
      {
        "name": "K-column (5)",
        "items": [
          {
            "furigana": "カ",
            "roumaji": "ka"
          },
          {
            "furigana": "キ",
            "roumaji": "ki"
          },
          {
            "furigana": "ク",
            "roumaji": "ku"
          },
          {
            "furigana": "ケ",
            "roumaji": "ke"
          },
          {
            "furigana": "コ",
            "roumaji": "ko"
          }
        ]
      },
      {
        "name": "S-column (5)",
        "items": [
          {
            "furigana": "サ",
            "roumaji": "sa"
          },
          {
            "furigana": "シ",
            "roumaji": "shi"
          },
          {
            "furigana": "ス",
            "roumaji": "su"
          },
          {
            "furigana": "セ",
            "roumaji": "se"
          },
          {
            "furigana": "ソ",
            "roumaji": "so"
          }
        ]
      },
      {
        "name": "T-column (5)",
        "items": [
          {
            "furigana": "タ",
            "roumaji": "ta"
          },
          {
            "furigana": "チ",
            "roumaji": "chi"
          },
          {
            "furigana": "ツ",
            "roumaji": "tsu"
          },
          {
            "furigana": "テ",
            "roumaji": "te"
          },
          {
            "furigana": "ト",
            "roumaji": "to"
          }
        ]
      },
      {
        "name": "N-column (5)",
        "items": [
          {
            "furigana": "ナ",
            "roumaji": "na"
          },
          {
            "furigana": "ニ",
            "roumaji": "ni"
          },
          {
            "furigana": "ヌ",
            "roumaji": "nu"
          },
          {
            "furigana": "ネ",
            "roumaji": "ne"
          },
          {
            "furigana": "ノ",
            "roumaji": "no"
          }
        ]
      },
      {
        "name": "H-column (5)",
        "items": [
          {
            "furigana": "ハ",
            "roumaji": "ha"
          },
          {
            "furigana": "ヒ",
            "roumaji": "hi"
          },
          {
            "furigana": "フ",
            "roumaji": "hu"
          },
          {
            "furigana": "ヘ",
            "roumaji": "he"
          },
          {
            "furigana": "ホ",
            "roumaji": "ho"
          }
        ]
      },
      {
        "name": "M-column (5)",
        "items": [
          {
            "furigana": "マ",
            "roumaji": "ma"
          },
          {
            "furigana": "ミ",
            "roumaji": "mi"
          },
          {
            "furigana": "ム",
            "roumaji": "mu"
          },
          {
            "furigana": "メ",
            "roumaji": "me"
          },
          {
            "furigana": "モ",
            "roumaji": "mo"
          }
        ]
      },
      {
        "name": "Y-column (3)",
        "items": [
          {
            "furigana": "ヤ",
            "roumaji": "ya"
          },
          {
            "furigana": "ユ",
            "roumaji": "yu"
          },
          {
            "furigana": "ヨ",
            "roumaji": "yo"
          }
        ]
      },
      {
        "name": "R-column (5)",
        "items": [
          {
            "furigana": "ラ",
            "roumaji": "ra"
          },
          {
            "furigana": "リ",
            "roumaji": "ri"
          },
          {
            "furigana": "ル",
            "roumaji": "ru"
          },
          {
            "furigana": "レ",
            "roumaji": "re"
          },
          {
            "furigana": "ロ",
            "roumaji": "ro"
          }
        ]
      },
      {
        "name": "W-column (3)",
        "items": [
          {
            "furigana": "ワ",
            "roumaji": "wa"
          },
          {
            "furigana": "ヲ",
            "roumaji": "wo"
          },
          {
            "furigana": "ン",
            "roumaji": "n"
          }
        ]
      },
      {
        "name": "Dakuten (25)",
        "items": [
          {
            "furigana": "ガ",
            "roumaji": "ga"
          },
          {
            "furigana": "ギ",
            "roumaji": "gi"
          },
          {
            "furigana": "グ",
            "roumaji": "gu"
          },
          {
            "furigana": "ゲ",
            "roumaji": "ge"
          },
          {
            "furigana": "ゴ",
            "roumaji": "go"
          },
          {
            "furigana": "ザ",
            "roumaji": "za"
          },
          {
            "furigana": "ジ",
            "roumaji": "ji"
          },
          {
            "furigana": "ズ",
            "roumaji": "zu"
          },
          {
            "furigana": "ゼ",
            "roumaji": "ze"
          },
          {
            "furigana": "ゾ",
            "roumaji": "zo"
          },
          {
            "furigana": "ダ",
            "roumaji": "da"
          },
          {
            "furigana": "ヂ",
            "roumaji": "ji"
          },
          {
            "furigana": "ヅ",
            "roumaji": "zu"
          },
          {
            "furigana": "デ",
            "roumaji": "de"
          },
          {
            "furigana": "ド",
            "roumaji": "do"
          },
          {
            "furigana": "バ",
            "roumaji": "ba"
          },
          {
            "furigana": "ビ",
            "roumaji": "bi"
          },
          {
            "furigana": "ブ",
            "roumaji": "bu"
          },
          {
            "furigana": "ベ",
            "roumaji": "be"
          },
          {
            "furigana": "ボ",
            "roumaji": "bo"
          },
          {
            "furigana": "パ",
            "roumaji": "pa"
          },
          {
            "furigana": "ピ",
            "roumaji": "pi"
          },
          {
            "furigana": "プ",
            "roumaji": "pu"
          },
          {
            "furigana": "ペ",
            "roumaji": "pe"
          },
          {
            "furigana": "ポ",
            "roumaji": "po"
          }
        ]
      },
      {
        "name": "Combinations (33)",
        "items": [
          {
            "furigana": "キャ",
            "roumaji": "kya"
          },
          {
            "furigana": "キュ",
            "roumaji": "kyu"
          },
          {
            "furigana": "キョ",
            "roumaji": "kyo"
          },
          {
            "furigana": "シャ",
            "roumaji": "sha"
          },
          {
            "furigana": "シュ",
            "roumaji": "shu"
          },
          {
            "furigana": "ショ",
            "roumaji": "sho"
          },
          {
            "furigana": "チャ",
            "roumaji": "cha"
          },
          {
            "furigana": "チュ",
            "roumaji": "chu"
          },
          {
            "furigana": "チョ",
            "roumaji": "cho"
          },
          {
            "furigana": "ニャ",
            "roumaji": "nya"
          },
          {
            "furigana": "ニュ",
            "roumaji": "nyu"
          },
          {
            "furigana": "ニョ",
            "roumaji": "nyo"
          },
          {
            "furigana": "ヒャ",
            "roumaji": "hya"
          },
          {
            "furigana": "ヒュ",
            "roumaji": "hyu"
          },
          {
            "furigana": "ヒョ",
            "roumaji": "hyo"
          },
          {
            "furigana": "ミャ",
            "roumaji": "mya"
          },
          {
            "furigana": "ミュ",
            "roumaji": "myu"
          },
          {
            "furigana": "ミョ",
            "roumaji": "myo"
          },
          {
            "furigana": "リャ",
            "roumaji": "rya"
          },
          {
            "furigana": "リュ",
            "roumaji": "ryu"
          },
          {
            "furigana": "リョ",
            "roumaji": "ryo"
          },
          {
            "furigana": "ギャ",
            "roumaji": "gya"
          },
          {
            "furigana": "ギュ",
            "roumaji": "gyu"
          },
          {
            "furigana": "ギョ",
            "roumaji": "gyo"
          },
          {
            "furigana": "ジャ",
            "roumaji": "ja"
          },
          {
            "furigana": "ジュ",
            "roumaji": "ju"
          },
          {
            "furigana": "ジョ",
            "roumaji": "jo"
          },
          {
            "furigana": "ビャ",
            "roumaji": "bya"
          },
          {
            "furigana": "ビュ",
            "roumaji": "byu"
          },
          {
            "furigana": "ビョ",
            "roumaji": "byo"
          },
          {
            "furigana": "ピャ",
            "roumaji": "pya"
          },
          {
            "furigana": "ピュ",
            "roumaji": "pyu"
          },
          {
            "furigana": "ピョ",
            "roumaji": "pyo"
          }
        ]
      },
      {
        "name": "Extended (30)",
        "items": [
          {
            "furigana": "イェ",
            "roumaji": "ye"
          },
          {
            "furigana": "ヴァ",
            "roumaji": "va"
          },
          {
            "furigana": "ヴィ",
            "roumaji": "vi"
          },
          {
            "furigana": "ヴ",
            "roumaji": "vu"
          },
          {
            "furigana": "ヴェ",
            "roumaji": "ve"
          },
          {
            "furigana": "ヴォ",
            "roumaji": "vo"
          },
          {
            "furigana": "ヴャ",
            "roumaji": "vya"
          },
          {
            "furigana": "ヴュ",
            "roumaji": "vyu"
          },
          {
            "furigana": "ヴョ",
            "roumaji": "vyo"
          },
          {
            "furigana": "シェ",
            "roumaji": "she"
          },
          {
            "furigana": "ジェ",
            "roumaji": "je"
          },
          {
            "furigana": "チェ",
            "roumaji": "che"
          },
          {
            "furigana": "ツァ",
            "roumaji": "tsa"
          },
          {
            "furigana": "ツィ",
            "roumaji": "tsi"
          },
          {
            "furigana": "ツェ",
            "roumaji": "tse"
          },
          {
            "furigana": "ツォ",
            "roumaji": "tso"
          },
          {
            "furigana": "ティ",
            "roumaji": "ti"
          },
          {
            "furigana": "テュ",
            "roumaji": "tyu"
          },
          {
            "furigana": "ディ",
            "roumaji": "di"
          },
          {
            "furigana": "デュ",
            "roumaji": "dyu"
          },
          {
            "furigana": "トゥ",
            "roumaji": "tu"
          },
          {
            "furigana": "ドゥ",
            "roumaji": "du"
          },
          {
            "furigana": "ファ",
            "roumaji": "fa"
          },
          {
            "furigana": "フィ",
            "roumaji": "fi"
          },
          {
            "furigana": "フェ",
            "roumaji": "fe"
          },
          {
            "furigana": "フォ",
            "roumaji": "fo"
          },
          {
            "furigana": "フュ",
            "roumaji": "fyu"
          },
          {
            "furigana": "ウィ",
            "roumaji": "wi"
          },
          {
            "furigana": "ウェ",
            "roumaji": "we"
          },
          {
            "furigana": "ウォ",
            "roumaji": "wo"
          }
        ]
      }
    ]
  }
]