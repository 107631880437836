import { App } from './app';
import { BACK_ARROW_PATH, ExerciceKind, EXERCICE_DATA, Section } from './constants';
import { appendCheckbox, appendElement, appendHeader, createPage } from './dom';
import { displayPracticePage } from './practice-page';

export function displayPreparationPage(section: Section) {
    let page = createPage();

    let header = appendHeader(page, {
        text: section.name,
        buttons: [{
            icon: BACK_ARROW_PATH,
            onClick: () => App.goBack()
        }]
    });

    let exerciceSelectionDiv = appendElement(page, 'div', {
        
    });

    appendElement(exerciceSelectionDiv, 'label', {
        htmlFor: 'exercice-select',
        textContent: 'Exercice: '
    });

    let exerciceSelect: HTMLSelectElement = appendElement(exerciceSelectionDiv, 'select', {
        className: 'exercice-select',
        id: 'exercice-select',
    });

    for (let exercice of section.exercices) {
        let { displayName } = EXERCICE_DATA[exercice];

        appendElement(exerciceSelect, 'option', {
            value: exercice,
            textContent: displayName
        })
    }

    if (section.exercices.length < 2) {
        exerciceSelectionDiv.style.display = 'none';
    }

    let list = appendElement(page, 'div', {
        className: 'bucket-list'
    });

    if (!section.wrapBuckets) {
        list.style.flexDirection = 'column';
    }

    let checkedBucketNames = (localStorage.getItem(section.name) || '').split(',');

    for (let i = 0; i < section.buckets.length; ++i) {
        let bucket = section.buckets[i];
        let item = appendCheckbox(list, {
            label: bucket.name,
            onChange: () => {
                onStateChange();
            }
        });

        item.classList.add('bucket-item');

        if (!section.wrapBuckets) {
            item.style.width = '100%';
        }

        bucket.checkbox = item;
    }

    let totalCount = section.buckets.reduce((acc, bucket) => acc + bucket.items.length, 0);
    let checkAllCheckbox = appendCheckbox(list, {
        label: `Check all (${totalCount})`,
        onChange: () => {
            let checkAll = section.buckets.some(bucket => !bucket.checkbox!.checked);

            for (let bucket of section.buckets) {
                bucket.checkbox!.checked = checkAll;
            }
        }
    });

    checkAllCheckbox.style.width = '100%';
    checkAllCheckbox.classList.add('bucket-item');

    let buttons = appendElement(page, 'div', {
        style: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px'
        }
    });

    let practiceButton = appendElement(buttons, 'div', {
        textContent: 'Practice',
        className: 'button',
        onContextMenu: (evt) => evt.preventDefault(),
        onClick: () => {
            let buckets = section.buckets.filter(bucket => bucket.checkbox!.checked);
            let exercice = exerciceSelect.value as ExerciceKind;
            displayPracticePage(section, { buckets, exercice });
        }
    });

    let onStateChange = () => {
        let noneChecked = section.buckets.every(bucket => !bucket.checkbox!.checked);
        let allChecked = section.buckets.every(bucket => bucket.checkbox!.checked);
        let checkedNames = section.buckets.filter(bucket => bucket.checkbox!.checked).map(bucket => bucket.name);

        if (noneChecked) {
            practiceButton.classList.add('disabled');
        } else {
            practiceButton.classList.remove('disabled');
        }

        // @ts-ignore
        checkAllCheckbox._checked = allChecked;
        localStorage.setItem(section.name, checkedNames.join(','));
    };

    for (let bucket of section.buckets) {
        if (checkedBucketNames.includes(bucket.name)) {
            // @ts-ignore
            bucket.checkbox._checked = true;
        }
    }

    onStateChange();

    App.displayPage(page);
}