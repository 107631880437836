import exercicesJson from '../data/exercices.json';

export const SECTIONS: Section[] = exercicesJson as Section[];

export const BACK_ARROW_PATH = '/images/back-arrow.svg';

export type Item = {
    word: string;
    furigana: string;
    roumaji: string;
    meaning: string;
}

export type ItemKind = "character" | "word";

export type Exercice = {
    displayName: string;
    instruction: string;
    instructionSelfEvaluate: string;
    getPrompt: (item: Item) => string;
    isValidAnswer: (item: Item, answer: string) => boolean;
    getHelpHtml: (item: Item) => string;
}

export type Section = {
    name: string;
    exercices: ExerciceKind[];
    itemKind: ItemKind;
    buckets: ItemBucket[];
    wrapBuckets: boolean;
}

export type ItemBucket = {
    name: string;
    items: Item[];
    checkbox?: HTMLInputElement;
}

// export const EXERCICE_KINDS = ['read', 'translate-from-target', 'translate-to-target'] as const;
export const EXERCICE_KINDS = ['read'] as const;

export type ExerciceKind = typeof EXERCICE_KINDS[number];

export const EXERCICE_DATA: { [Key in ExerciceKind]: Exercice } = {
    'read': {
        displayName: 'Read',
        instruction: `Type this $KIND's roumaji equivalent.`,
        instructionSelfEvaluate: `Think of this $KIND's roumaji equivalent.`,
        getPrompt: (item: Item) => item.furigana,
        isValidAnswer: (item: Item, answer: string) => answer === item.roumaji,
        getHelpHtml: (item: Item) => {
            let help = `<strong>${item.roumaji}</strong>`;

            if (item.meaning) {
                help += ` ; ${item.meaning}`;
            }

            return help;
        }
    },
    // 'translate-from-target': {
    //     displayName: 'Translate to English',
    //     instruction: `Type this $KIND's English translation.`,
    //     instructionSelfEvaluate: `Think of this $KIND's English translation.`,
    //     getPrompt: (item) => item.furigana.replace('~', ''),
    //     isValidAnswer: (item, answer) => {
    //         let meaningList = item.meaning.split(',').map(meaning => {
    //             return meaning
    //                 .replace('~', '')
    //                 .replace(/\(.*\)/, '')
    //                 .trim()
    //         });

    //         console.log(meaningList)
    //         return meaningList.includes(answer)
    //     },
    //     getHelpHtml: (item) => `${item.roumaji.replace('~', '~ ')} ; <strong>${item.meaning.replace('~', '~ ')}</strong>`
    // },
    // 'translate-to-target': {
    //     displayName: 'Translate to Japanese',
    //     instruction: `Type this $KIND's Japanese translation in roumaji.`,
    //     instructionSelfEvaluate: `Think of this $KIND's Japanese translation in roumaji.`,
    // }
};