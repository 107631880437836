import { App } from './app';
import { SECTIONS } from './constants';
import { appendElement, appendHeader, createPage } from './dom';
import { displayPreparationPage } from './preparation-page';

export function displayMenuPage() {
    let page = createPage();

    let header = appendHeader(page, {
        text: 'Joru'
    });

    let menu = appendElement(page, 'div', {
        className: 'menu'
    });

    for (let i = 0; i < SECTIONS.length; ++i) {
        let section = SECTIONS[i];
        let number = i + 1;

        appendElement(menu, 'div', {
            textContent: `${number} - ${section.name}`,
            className: 'menu-item',
            onClick: () => displayPreparationPage(section),
            onContextMenu: (evt) => evt.preventDefault(),
        });
    }

    App.displayPage(page);
}