import { clearElement } from './dom';

const ROOT_ELT = document.querySelector('#app') as HTMLElement;

export class App {
    static pages: HTMLElement[] = []

    static _setPage(page: HTMLElement) {
        clearElement(ROOT_ELT);
        ROOT_ELT.appendChild(page);
    }

    static displayPage(page: HTMLElement) {
        this.pages.push(page);
        this._setPage(page);
    }

    static goBack() {
        if (this.pages.length > 1) {
            this.pages.pop();
            this._setPage(this.pages[this.pages.length - 1]);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(App);