export type Header = {
    text: string;
    buttons?: HeaderButton[];
}

export type HeaderButton = {
    icon?: string;
    onClick: () => void;
}

export function createPage() {
    let container = document.createElement('div');

    container.className = 'root';

    return container;
}

export function appendHeader(parent: HTMLElement, options: Header): HTMLDivElement {
    let {
        text,
        buttons = []
    } = options;

    let headerElement: HTMLDivElement = appendElement(parent, 'div', {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    let left = appendElement(headerElement, 'div', {
        style: {
            flex: 1
        }
    });
    let title = appendElement(headerElement, 'h1', {
        textContent: text,
        style: {
            flex: 3
        }
    });
    let right = appendElement(headerElement, 'div', {
        style: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row-reverse',
            height: 'fit-content'
        }
    });

    for (let { icon = '', onClick } of buttons) {
        let buttonElt = appendElement(right, 'div', {
            className: 'header-button',
            textContent: icon,
            onContextMenu: (evt) => evt.preventDefault(),
            onClick
        });

        if (icon.includes('.')) { // image
            buttonElt.textContent = '';

            let image = appendElement(buttonElt, 'img', {
                src: `/images/back-arrow.svg`
            })

            // buttonElt.content = `url(${icon})`;
            // buttonElt.style.backgroundImage = `url(/images/back-arrow.svg)`;
            // buttonElt.style.backgroundImage = `url(/images/checkmark-blue.svg)`;
        }
    }

    return headerElement;
}

let COUNTER = 0;

export type Checkbox = {
    prefix?: string;
    label?: string;
    onChange?: () => void;
}

export function appendCheckbox(parent: HTMLElement, params: Checkbox): HTMLInputElement {
    let id = `${++COUNTER}`;
    let rootElt: HTMLLabelElement = appendElement(parent, 'label', {
        className: 'checkbox-root',
        htmlFor: id,
        style: { display: 'block' }
    });
    let prefixElt: HTMLSpanElement = appendElement(rootElt, 'span', {
        innerHTML: params.prefix
    });
    let checkboxElt: HTMLInputElement = appendElement(rootElt, 'input', {
        type: 'checkbox',
        id
    });
    let labelElt: HTMLSpanElement = appendElement(rootElt, 'span', {
        className: 'checkbox-label',
        innerHTML: params.label
    });

    Object.defineProperty(rootElt, 'checked', {
        get() {
            return checkboxElt.checked;
        },
        set(value) {
            if (value === checkboxElt.checked) {
                return;
            }

            checkboxElt.checked = value;
            params.onChange?.();
        }
    });

    Object.defineProperty(rootElt, '_checked', {
        get() {
            return checkboxElt.checked;
        },
        set(value) {
            checkboxElt.checked = value;
        }
    });

    Object.defineProperty(rootElt, 'disabled', {
        get() {
            return checkboxElt.disabled;
        },
        set(value) {
            checkboxElt.disabled = value;
        }
    });

    if (params.onChange) {
        checkboxElt.onchange = params.onChange;
    }

    return rootElt as any
}

export function clearElement(root: HTMLElement) {
    while (root.firstChild) {
        root.removeChild(root.firstChild);
    }
}

export type HtmlElementParameters = {
    textContent?: string;
    innerHTML?: string;
    className?: string | string[];
    onClick?: () => void;
    onContextMenu?: (evt: MouseEvent) => void;
    style?: { [key: string]: string | number };
    attributes?: { [key: string]: string | number };
    htmlFor?: string;
    id?: string;
    value?: string;
    src?: string;
    type?: string;
}

export function appendElement<Element extends HTMLElement = HTMLElement>(parent: HTMLElement | null, type: string, params: HtmlElementParameters = {}): Element {
    let element: any = document.createElement(type);

    if (params.textContent) {
        element.textContent = params.textContent;
    }

    if (params.innerHTML) {
        element.innerHTML = params.innerHTML;
    }

    if (params.style) {
        for (let [key, value] of Object.entries(params.style)) {
            element.style[key] = value;
        }
    }
    
    if (params.attributes) {
        for (let [key, value] of Object.entries(params.attributes)) {
            element[key] = value;
        }
    }

    if (params.id) {
        element.id = params.id;
    }

    if (params.type) {
        element.type = params.type;
    }

    if (params.className) {
        if (Array.isArray(params.className)) {
            element.className = params.className.join(' ');
        } else {
            element.className = params.className;
        }
    }

    if (params.onClick) {
        element.onclick = params.onClick;
    }

    if (params.onContextMenu) {
        element.oncontextmenu = params.onContextMenu;
    }

    if (params.htmlFor) {
        element.htmlFor = params.htmlFor;
    }

    if (params.value) {
        element.value = params.value;
    }

    if (params.src) {
        element.src = params.src;
    }

    if (parent) {
        parent.appendChild(element);
    }

    return element as Element;
}